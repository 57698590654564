<template>
  <div id="Case">
    <div class="education" ref="education">
      <h2 id="educational">学校案例</h2>
      <!-- <p>School Case</p> -->
      <div class="case-box">
        <ul>
          <li v-for="(item, index) in schoolCase" :key="index">
            <div>
              <img :src="item.img" alt="" />
            </div>
            <p>
              {{ item.name }}
            </p>
            <div class="more" @click.stop="$router.push(`${item.url}`)">
              了解更多
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="regional" ref="regional">
      <h2 id="regional">区域案例</h2>
      <!-- <p>Regional Case</p> -->
      <div class="case-box">
        <ul>
          <li v-for="(item, index) in regionalCase" :key="index">
            <div>
              <img :src="item.img" alt="" />
            </div>
            <p>
              {{ item.name }}
            </p>
            <div class="more" @click.stop="$router.push(`${item.url}`)">
              了解更多
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import schoolCase_bg_1 from '@/assets/images/partnersCase/case_bg_1.png'
import schoolCase_bg_2 from '@/assets/images/partnersCase/case_bg_2.png'
import schoolCase_bg_3 from '@/assets/images/partnersCase/case_bg_3.png'
import schoolCase_bg_4 from '@/assets/images/partnersCase/case_bg_4.png'
import schoolCase_bg_5 from '@/assets/images/partnersCase/case_bg_5.png'
import schoolCase_bg_7 from '@/assets/images/partnersCase/case_bg_7.png'
import schoolCase_bg_8 from '@/assets/images/partnersCase/case_bg_8.png'
import schoolCase_bg_9 from '@/assets/images/partnersCase/case_bg_9.png'
import schoolCase_bg_10 from '@/assets/images/partnersCase/case_bg_10.png'
import schoolCase_bg_11 from '@/assets/images/partnersCase/case_bg_11.jpg'
import regionalCase_bg_1 from '@/assets/images/partnersCase/regionalCase_bg_1.png'
import regionalCase_bg_2 from '@/assets/images/partnersCase/regionalCase_bg_2.png'
import regionalCase_bg_3 from '@/assets/images/partnersCase/regionalCase_bg_3.png'
import regionalCase_bg_4 from '@/assets/images/partnersCase/regionalCase_bg_4.jpg'
import regionalCase_bg_5 from '@/assets/images/partnersCase/regionalCase_bg_5.jpg'
import regionalCase_bg_6 from '@/assets/images/partnersCase/regionalCase_bg_6.jpg'
import regionalCase_bg_8 from '@/assets/images/partnersCase/regionalCase_bg_8.png'

export default {
  name: 'Case',
  metaInfo: {
    title: '伙伴与客户',
    meta: [
      { name: 'keywords', content: '晓羊集团' },
      { name: 'description', content: '' },
    ],
  },
  data() {
    return {
      schoolCase: [
        {
          name: '四川省天府七中',
          img: schoolCase_bg_1,
          url: '/partners-customers/case/case1',
        },
        {
          name: '北京市陈经纶中学',
          img: schoolCase_bg_2,
          url: '/partners-customers/case/case2',
        },
        {
          name: '北京市第三十五中学',
          img: schoolCase_bg_3,
          url: '/partners-customers/case/case3',
        },
        {
          name: '东莞市松山湖北区学校',
          img: schoolCase_bg_4,
          url: '/partners-customers/case/case4',
        },
        {
          name: '南京市上元中学',
          img: schoolCase_bg_5,
          url: '/partners-customers/case/case5',
        },

        {
          name: '深圳市南山区香山里小学',
          img: schoolCase_bg_7,
          url: '/partners-customers/case/case7',
        },
        {
          name: '四川省宣汉中学',
          img: schoolCase_bg_8,
          url: '/partners-customers/case/case8',
        },
        {
          name: '宜昌市青少年教育实践教育基地',
          img: schoolCase_bg_9,
          url: '/partners-customers/case/case9',
        },
        {
          name: '涿州市实验中学',
          img: schoolCase_bg_10,
          url: '/partners-customers/case/case10',
        },
        {
          name: '四川师范大学附属实验学校',
          img: schoolCase_bg_11,
          url: '/partners-customers/case/case17',
        },
      ],
      regionalCase: [
        {
          name: '四川省成都市武侯区',
          img: regionalCase_bg_1,
          url: '/partners-customers/case/case11',
        },
        {
          name: '安徽省芜湖市湾沚区',
          img: regionalCase_bg_2,
          url: '/partners-customers/case/case12',
        },
        {
          name: '安徽省芜湖市南陵县',
          img: regionalCase_bg_3,
          url: '/partners-customers/case/case13',
        },
        {
          name: '北京市门头沟区',
          img: regionalCase_bg_4,
          url: '/partners-customers/case/case14',
        },
        {
          name: '湖南省张家界市桑植县',
          img: regionalCase_bg_5,
          url: '/partners-customers/case/case15',
        },
        {
          name: '四川省泸州市江阳区、合江县',
          img: regionalCase_bg_6,
          url: '/partners-customers/case/case16',
        },
        {
          name: '山东、四川、内蒙古多地区',
          img: regionalCase_bg_8,
          url: '/partners-customers/case/case18',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  mounted() {
    //模拟触发滚动1像素，为了触发在首屏应该展示的动画（numbers动画），也能顺便返回页面顶部
    this.$nextTick(() => {
      // console.log()
      // // window.location.reload()
      setTimeout(() => {
        window.scroll({
          top: this.$refs[this.$route.hash.replace('#', '')].offsetTop - 145,
          left: 0,
          behavior: 'smooth',
        })
      }, 100)
    })
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
%Case {
  width: 100%;
  padding-top: 155px;
  position: relative;
  background: $--color-white;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('padding-right', 240);
  text-align: center;
  h2 {
    font-size: 32px;
  }
  > p {
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .case-box {
    margin: 78px auto 0;
    width: 80%;
    max-width: 1320px;
    overflow: hidden;
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
      grid-auto-flow: row dense;
      flex-wrap: wrap;

      li {
        width: 310px;
        height: 350px;
        background: $--color-white;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-bottom: 60px;
        & > div {
          overflow: hidden;
        }
        img {
          width: 310px;
          height: 190px;
          transition: all 0.6s;
          object-fit: cover;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        p {
          padding: 16px 0 0 16px;
          text-align: left;
          font-size: 18px;
        }
        .more {
          border-radius: 4px;
          border: 1px solid #979797;
          width: 137px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          font-size: 14px;
          color: #333333;
          margin: 32px 0 0 16px;
        }
        .more:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.view-pc > #Case {
  @extend %Case;
}

.view-mobile > #Case {
  @extend %Case;
  margin-top: 44px;
  padding-top: 15px;
  h2 {
    margin-bottom: 0;
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    .case-box {
      margin-top: 40px;

      ul {
        width: 60%;
        margin: 0 auto;
        justify-content: center;
        li {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
